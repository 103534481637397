import { Stack } from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import { FunctionComponent } from "react"
import { getOldProjects } from "../../api/projects"
import Loading from "../common/Loading"
import FinishedProject from "./FinishedProject"
import { useGameId } from "../../hooks/query"



const PastProjects: FunctionComponent = () => {
    const gameId = useGameId()
    const { data: projects, isLoading } = useQuery({ queryKey: ['old-projects', gameId], queryFn: () => getOldProjects(gameId) })

    if (isLoading) return <Loading />
    return (
        <Stack gap="2">
            {projects?.map((project) => {
                return (
                    <FinishedProject key={project.id} {...project} />
                )
            })}
        </Stack>

    )
}


export default PastProjects