import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(inputAnatomy.keys)

const baseStyle = definePartsStyle({
    // define the part you're going to style
    field: {
        borderRadius: "none",
        background: "darkred",
        _focus: {
            borderColor: "lightgreen",
        },
        
        _placeholder: {
            color: 'orange.400', // change the placeholder color
        },
    },
})

export const Input = defineMultiStyleConfig({ baseStyle })