import { Box, Center, Container, HStack, Link, List, Stack, Switch, Text, useToast } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import { useConfig, useGameId, useHQInfo, useResources } from "../../../hooks/query"
import Button from "../../common/Button"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { HQInfo, setSleepMode, useTicks } from "../../../api/game"
import HelpText from "../../common/HelpText"

const SleepMode: FunctionComponent = () => {
    const queryClient = useQueryClient()
    const gameId = useGameId()
    const toast = useToast()
    const config = useConfig()

    const { data } = useHQInfo()
    const resources = useResources()

    const sleepMutation = useMutation({
        mutationFn: (value: boolean) => setSleepMode(gameId, value),
        onMutate: (value) => {
            queryClient.setQueryData(["hqInfo", gameId], (prev: HQInfo) => {
                return {
                    ...prev,
                    hq: {
                        ...prev.hq,
                        sleepMode: value,
                    },
                }
            })
        },
        onError: (error, variables, context) => {
            queryClient.invalidateQueries({ queryKey: ["hqInfo", gameId] })
            toast({
                title: "An error occured while changing sleep mode.",
                status: "error",
                duration: 9000,
                isClosable: true,
            })
        },
    })

    const ticksMutation = useMutation({
        mutationFn: (value: number) => useTicks(gameId, value),
        onSuccess: (data) => {
            queryClient.setQueryData(["gatherResources", gameId], data)
        },
    })

    const showTicks = !data?.hq.sleepMode && resources.ticks > 0
    const ticks10 = resources.ticks > 10 ? 10 : resources.ticks
    const ticks100 = resources.ticks > 100 ? 100 : resources.ticks

    if (!config?.modeConfig.sleep_mode) return null

    return (
        <Box fontSize="xs" whiteSpace={"nowrap"}>
            <Stack gap="0">
                <HStack>
                    <Text>Sleep mode</Text>
                    <Switch
                        id="sleep-mode"
                        isChecked={data?.hq.sleepMode}
                        colorScheme="orange"
                        onChange={(e) => {
                            sleepMutation.mutate(e.target.checked)
                        }}
                    />
                    <HelpText
                        tooltip={
                            <Stack>
                                <Text>
                                    When you activate the sleep mode, you accumulates "ticks" instead of earning
                                    resources (except for guardian and knight).
                                </Text>
                                <Text>
                                    When leaving the sleep mode, you will be able to spend these ticks to earn
                                    resources. One tick is equivalent to 20s.
                                </Text>
                                <Text>You can't earn more than 8 hours of accumulated ticks (1440 ticks).</Text>
                            </Stack>
                        }
                    ></HelpText>
                </HStack>
                <HStack gap="0">
                    <Text mr="1">Ticks : {resources.ticks}</Text>
                    <Box visibility={showTicks ? "visible" : "hidden"}>
                        <Button
                            size="sm"
                            m="0"
                            tooltip={`Spend ${ticks10} tick (~${Math.round(ticks10 / 3)} minutes)`}
                            onClick={() => ticksMutation.mutate(ticks10)}
                            visibility={showTicks ? "visible" : "hidden"}
                        >
                            {ticks10}
                        </Button>

                        <Button
                            visibility={showTicks && resources.ticks > 10 ? "visible" : "hidden"}
                            size="sm"
                            tooltip={`Spend ${ticks100} tick (~${Math.round(ticks100 / 3)} minutes)`}
                            onClick={() => ticksMutation.mutate(ticks100)}
                        >
                            {ticks100}
                        </Button>
                    </Box>
                </HStack>
            </Stack>
        </Box>
    )
}

export default SleepMode
