import {
    createMultiStyleConfigHelpers,
    defineStyleConfig,
    extendTheme,
    StyleFunctionProps,
} from "@chakra-ui/react"
import { cardAnatomy } from '@chakra-ui/anatomy'
import { Factions } from "../types"
import { Card } from "./card"
import { Modal } from "./modal"
import { Button } from "./button"
import { Menu } from "./menu"
import { Popover } from "./popover"
import { Input } from "./input"

const customTheme = {
    styles: {
        global: (props: StyleFunctionProps) => ({
            body: {
                cursor: "url('/ui/cursor.png'), auto",
                bg: "orange.800",
                color: "orange.100",
                fontSize: "0.8rem"
            },
            a: {
                _hover: {
                    textDecoration: "underline",
                }
            }

        }),
    },
    fonts: {
        heading: `'Retro gaming', sans-serif`,
        body: `'Retro gaming', sans-serif`,
    },
    app: {
        factions: {
            [Factions.RED]: "red.800",
            [Factions.GREEN]: "green.800",
            [Factions.BLUE]: "blue.800",
            [Factions.YELLOW]: "yellow.800",
        },
    },
    layerStyles: {
        info: {
            bg: "orange.100",
            border: "1px solid",
            borderColor: "orange.500",
            color: "black",
            boxShadow: "md",
            padding: 2
        },
        error: {
            bg: "red.800",
            border: "1px solid",
            borderColor: "red.500",
            color: "white",
            boxShadow: "md",
            padding: 2
        },
        warning: {
            bg: "red.800",
            border: "1px solid",
            borderColor: "red.500",
            color: "white",
            boxShadow: "md",
            padding: 2
        },
    },
    semanticTokens: {
        colors: {
            RED: 'red.800',
            GREEN: 'green.800',
            BLUE: 'blue.800',
            YELLOW: 'yellow.800',
        },
    },

    // 2. Extend the theme to include custom colors, fonts, etc

    colors: {
        orange: {
            50: "#F7F3ED",
            100: "#E9DDCE",
            200: "#DBC7AE",
            300: "#CDB18E",
            400: "#BF9B6E",
            500: "#B0854F",
            600: "#8D6A3F",
            700: "#6A502F",
            800: "#47351F",
            900: "#231B10",
        },
        yellow: {
            50: "#FFF8E6",
            100: "#FEEDB8",
            200: "#FEE18B",
            300: "#FED55D",
            400: "#FDC930",
            500: "#FDBD02",
            600: "#CA9702",
            700: "#987101",
            800: "#654C01",
            900: "#332600",
        },
        teal: {
            50: "#EFF5F5",
            100: "#D2E3E4",
            200: "#B6D1D3",
            300: "#99C0C2",
            400: "#7CAEB1",
            500: "#5F9CA0",
            600: "#4C7D80",
            700: "#395E60",
            800: "#263E40",
            900: "#131F20",
        },
        red: {
            50: "#FDE8E8",
            100: "#F9BEBE",
            200: "#F59494",
            300: "#F16A6A",
            400: "#ED4040",
            500: "#E91616",
            600: "#BA1212",
            700: "#8C0D0D",
            800: "#5D0909",
            900: "#2F0404"
        },
        green: {
            "50": "#EFF6EF",
            "100": "#D3E5D2",
            "200": "#B8D3B5",
            "300": "#9CC298",
            "400": "#80B17B",
            "500": "#64A05F",
            "600": "#50804C",
            "700": "#3C6039",
            "800": "#284026",
            "900": "#142013"
        }


    },
    fontSizes: {
        xs: "10px",
        sm: "12px",
        md: "14px",
        lg: "16px",
        xl: "18px",
        "2xl": "24px",
        "3xl": "28px",
        "4xl": "36px",
        "5xl": "48px",
        "6xl": "64px",
    },
}
const Heading = defineStyleConfig({
    baseStyle: {
        color: "orange.100",
    }
})

const Text = defineStyleConfig({
    baseStyle: {
    },
    variants: {
        dark: {
            color: "orange.900",
        },
        positive: {
            color: "green.700",
        },
        negative: {
            color: "red.700",
        },
        discreet: {
            fontSize: "0.8em",
        },
    },
})



const Tabs = defineStyleConfig({
    baseStyle: {
        tab: {
            display: 'flex',
        }
    },
    variants: {
        main: {
            tab: {
                display: 'flex',
                border: '2px solid',
                borderColor: 'transparent',
                bg: "orange.500",
                borderRadius: 'none',
                minWidth: ['auto', 'auto', '150px'],
                justifyContent: 'center',
                alignItems: 'center',
                padding: 2,
                _selected: {
                    bg: "orange.200",
                    color: "black",
                    borderBottom: '0',
                    borderColor: 'red.700',
                },
            },
            tablist: {
                display: "flex",
                justifyContent: "center"
            },
            tabpanel: {
                border: '2px solid',
                borderColor: 'orange.800',
                boxShadow: 'md',
            },
        },
        chat: {
            tab: {
                display: 'flex',
                border: '1px solid',
                borderBottom: 0,
                borderColor: "orange.700",
                bg: "orange.500",
                borderRadius: 'none',
                boxShadow: 'md',
                minWidth: ['auto', 'auto', '100px'],
                justifyContent: 'center',
                alignItems: 'center',
                padding: 0,
                _hover: {
                    bg: "orange.400",
                    color: "black",
                },
                _selected: {
                    bg: "orange.200",
                    color: "black",
                    borderBottom: '0',
                    borderColor: 'red.700',
                },
            },
            tablist: {
                display: "flex",
            },
            tabpanel: {
                border: '1px solid',
                borderColor: 'orange.800',
                boxShadow: 'md',
                padding: "1"
            },

        }
    }
})


const Panel = defineStyleConfig({
    // The styles all Cards have in common
    baseStyle: {
        backgroundColor: 'orange.500',
        p: 4,
        m: 4
    },
    // Two variants: rounded and smooth
    variants: {
        rounded: {
            borderRadius: 'xl',
            boxShadow: 'inset 6px 6px 10px 0px #00000080',
            borderColor: 'orange.200',
            borderWidth: '2px',
        },
        smooth: {
            borderRadius: 'base',
            boxShadow: 'md',
        },
        withBorder: {
            borderImage: 'url(/ui/border.png) 15 / 10px',
            borderImageRepeat: "stretch",
            borderRadius: "xl",
            boxShadow: "md",
        },
        badge: {
            p: 1,
            m: 1,
            borderRadius: 'sm',
            backgroundColor: 'orange.100',
            borderColor: 'orange.200',
            borderWidth: '1px',
            fontSize: "sm",
            boxShadow: "md",
            color: "black"
        },
        achievement: {
            p: 2,
            m: 1,
            // borderRadius: 'sm',
            color: 'orange.800',
            backgroundColor: 'orange.500',
            borderColor: 'orange.200',
            borderWidth: '1px',
            fontSize: "sm",
            transition: ".2s ease-out",
            boxShadow: "md",
            borderImage: 'url(/ui/border.png) 15 / 10px',
            borderImageRepeat: "stretch",
            borderRadius: "xl",
            _hover: {
                backgroundColor: 'orange.400',
                boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.5)',
                transform: 'translate3d(-2px,-2px,0)'
            }

        }
    },
    // The default variant value
    defaultProps: {
        variant: 'smooth',
    },
})


const Link = defineStyleConfig({
    baseStyle: {
        color: "blue.600",
        textDecoration: "underline",
        _hover: {
            color: "red.600",
        },
    },
    variants: {
        transparent: {
            textDecoration: "none",
            color: "inherit",
        }
    }
})

const Checkbox = defineStyleConfig({
    defaultProps: {
        colorScheme: "orange",
    }
})


import { switchAnatomy } from '@chakra-ui/anatomy'

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(switchAnatomy.keys)

const baseStyle = definePartsStyle({
    // define the part you're going to style
    container: {
        borderRadius: "none",
    },
    thumb: {
        bg: 'orange.500',
        _checked: {
            bg: 'black',
        },
    },
    track: {
        borderRadius: "none",
        bg: 'orange.200'
    },
})

export const Switch = defineMultiStyleConfig({ baseStyle })
// import { inputAnatomy } from '@chakra-ui/anatomy'
// const { definePartsStyle, defineMultiStyleConfig } =
//     createMultiStyleConfigHelpers(inputAnatomy.keys)

// const baseStyle = definePartsStyle({
//     variants: {
//         outline: {
//             // define the part you're going to style
//             field: {
//                 borderColor: "gray.200"
//             }
//         }
//     }
// })

// const Input = defineMultiStyleConfig({ baseStyle })


export const theme = extendTheme(customTheme, { components: { Text, Heading, Button, Tabs, Panel, Card, Modal, Menu, Popover, Link, Checkbox, Switch, Input } })
