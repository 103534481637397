import BaseStore from "./BaseStore"
import RootStore from "./RootStore"
import { queryClient } from "../query_client"
import ReconnectingWebSocket from "reconnecting-websocket"
import { Projects } from "../api/projects"
import { Factions, ProjectType } from "../types"
import { WS_BASE_URL } from "../config"
import { action, makeObservable, observable } from "mobx"


export default class ProjectsStore extends BaseStore {

    // notifications: Notification[] = []
    projectsSocket: ReconnectingWebSocket | null = null
    selectedProject: ProjectType | null = null

    constructor(rootStore: RootStore) {
        super(rootStore)
        makeObservable(this, {
            // projects: observable,
            selectedProject: observable,
            setSelectedProject: action,
        })
        // onBecomeObserved(this, "projectsSocket", this.startProjectsSocket.bind(this))
        // onBecomeUnobserved(this, "projectsSocket", this.stopProjectsSocket.bind(this))
    }


    startProjectsSocket() {
        // connect to projects websocket
        if (this.projectsSocket?.OPEN || !this.rootStore.userStore.player?.userId)
            return

        const gameId = this.rootStore.userStore.gameId
        this.projectsSocket = new ReconnectingWebSocket(`${WS_BASE_URL}game/${gameId}/projects`, ['Token', `${this.rootStore.userStore.apiToken}`], {
            debug: false
        })
        this.projectsSocket.addEventListener('message', (event) => {
            console.log("receive message")
            const payload: { name: ProjectType, workers: number, type: "permanent", completed: boolean, faction: Factions }
                | { id: number, faction: Factions, workers: number, type: "event" } = JSON.parse(event.data)

            const playerFaction = this.rootStore.userStore.player?.faction
            if (payload.type === "permanent" && payload.faction !== playerFaction)
                return

            else {
                queryClient.setQueryData(['projects', gameId], (old: Projects | undefined) => {
                    console.log("old", old)

                    if (old === undefined)
                        return old

                    let permanent = old.permanent
                    if (payload.type === "permanent" && payload.completed) {
                        permanent = []
                        const completedProjects = old.permanent.filter(project => project.completed).map(project => project.name)
                        completedProjects.push(payload.name)
                        for (const project of old.permanent) {
                            permanent.push({
                                ...project,
                                completed: completedProjects.includes(project.name),
                                // check if all requires are completed
                                unlocked: project.requires?.every(req => completedProjects.includes(req)) || !project.requires?.length,
                            })
                        }
                    }


                    return {
                        permanent: permanent.map(project => {
                            if (payload.type === "permanent" && project.name === payload.name) {
                                return {
                                    ...project,
                                    workers: payload.workers
                                }
                            }
                            return project
                        }),
                        event: old.event.map(project => {
                            if (payload.type === "event" && project.id === payload.id) {
                                return {
                                    ...project,
                                    workers: {
                                        ...project.workers,
                                        [payload.faction]: payload.workers
                                    }
                                }
                            }
                            return project
                        }),
                        last: old.last
                    }
                })
            }
        })
    }

    stopProjectsSocket() {
        // disconnect from websocket
        this.projectsSocket?.close()
        this.projectsSocket = null
    }

    setSelectedProject(value: ProjectType) {
        // set selected project
        this.selectedProject = value
    }


}
