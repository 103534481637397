import { Box, Button, Heading, Text } from "@chakra-ui/react"
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { useMutation, useQuery } from "@tanstack/react-query"
import { becomePremium, createCheckoutSession } from "../api/payments"

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.
const stripePromise = loadStripe(
    "pk_test_51PBlGSDL0Wgivksskn6q85IqriPDuzrGXo0oIj0rXYsOSBNGBdH5FhuTeV8JlEBQ40otOdiVojJS8hxsmWjiIUvr0051K5wh9Z"
)

const CheckoutPage = () => {
    const { data, error } = useQuery({
        queryKey: ["checkout-session"],
        queryFn: createCheckoutSession,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        gcTime: 0,
        refetchInterval: 0,
    })

    // only works when payement is disabled on server
    const becomePremiumMutation = useMutation({
        mutationFn: () => becomePremium(),
        onSuccess: () => {
            window.location.href = "/"
        },
    })

    const options = (data && { clientSecret: data.client_secret }) || {}

    return (
        <Box id="checkout" m={4}>
            {error && <Heading>Error loading checkout page. Retry later.</Heading>}

            {data?.enabled && (
                <>
                    {data && data.client_secret && (
                        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
                            <EmbeddedCheckout />
                        </EmbeddedCheckoutProvider>
                    )}
                </>
            )}

            {!data?.enabled && (
                <Box>
                    <Heading>Checkout is disabled.</Heading>
                    <Text>Click this button to become premium for free (test server).</Text>
                    <Button onClick={() => becomePremiumMutation.mutate()}>Become premium</Button>
                </Box>
            )}
        </Box>
    )
}

export default CheckoutPage
