import { Suspense } from "react"
import { RouterProvider } from "react-router-dom"
import "./App.css"
import "./i18n"
import { router } from "./router"
import "./sentry"

function App() {
    return (
        <Suspense fallback="Loading...">
            <RouterProvider router={router} />
        </Suspense>
    )
}

export default App
