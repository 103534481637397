import { Box, HStack, Text } from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import { FunctionComponent } from "react"
import { getSurveys, useSurveys } from "../../api/faction"
import { useFaction, useGameId } from "../../hooks/query"
import Survey from "./Survey"
import { Role } from "../../api/game"
import { default as RoleComponent } from "../common/Role"
import Link from "../common/Link"

interface PlayerProps {
    id: number
    name: string
    role: Role
}
const Player: FunctionComponent<PlayerProps> = ({ id, name, role }) => {
    return (
        <HStack justify="space-between">
            <Link to={`/player/${name}`} color="orange.100">
                {name}
            </Link>
            <RoleComponent role={role} />
        </HStack>
    )
}

export default Player
