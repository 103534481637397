import { Box, Center, Container, List, Stack, Text } from "@chakra-ui/react"
import { FunctionComponent } from "react"

import { usePlayer } from "../../hooks/query"
import Link from "../common/Link"

interface MainMenuLinkProps {
    to: string
    children: string
    gold?: boolean
}
const MainMenuLink = ({ to, children, gold }: MainMenuLinkProps) => {
    return (
        <Link
            to={to}
            textDecoration={"none"}
            background={
                gold
                    ? "radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);"
                    : undefined
            }
            bgColor={!gold ? "orange.800" : undefined}
            color={gold ? "white" : "orange.100"}
            _activeLink={{ bgColor: "orange.900", color: "orange.200" }}
        >
            <Box p="2" border="1px solid" borderColor="orange.900">
                {children}
            </Box>
        </Link>
    )
}

const MainMenu: FunctionComponent = () => {
    const player = usePlayer()

    return (
        <Box mt="4">
            <Stack p="2" gap="0">
                <MainMenuLink to="/games">Games</MainMenuLink>
                <MainMenuLink to="/group">Group</MainMenuLink>
                <MainMenuLink to={`/player/${player?.username}`}>Profile</MainMenuLink>
                <MainMenuLink to="/settings">Settings</MainMenuLink>
                <MainMenuLink to={`/register`}>{player?.temporary ? "Register" : "Account"}</MainMenuLink>
                {!player?.isPremium && (
                    <MainMenuLink to={`/premium`} gold>
                        Become premium
                    </MainMenuLink>
                )}
            </Stack>
        </Box>
    )
}

export default MainMenu
