import { Box, Button, Flex, Heading, Icon, SimpleGrid, Text } from "@chakra-ui/react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { FunctionComponent } from "react"
import { HQInfo } from "../../api/game"
import { pickSpecialization, specsInfos } from "../../api/specialization"
import { SpecializationType, SupportType } from "../../types"
import Panel from "../common/Panel"
import { useTranslation } from "react-i18next"
import GameIcon from "../common/GameIcon"
import { SPECIALIZATION_TO_SUPPORT } from "../../config"
import { RiUserFill } from "react-icons/ri"
import HelpText from "../common/HelpText"
import { useGameId } from "../../hooks/query"

type SpecializationProps = {
    name: SpecializationType
}
const Specialization: FunctionComponent<SpecializationProps> = ({ name }) => {
    const queryClient = useQueryClient()
    const { t } = useTranslation()
    const gameId = useGameId()

    console.log(gameId)
    const pick = useMutation({
        mutationFn: () => pickSpecialization(gameId, name),
        onMutate: () => {},
        onSuccess: () => {
            queryClient.setQueryData(["hqInfo", gameId], (old: HQInfo | undefined) => {
                if (!old) return
                return {
                    ...old,
                    hq: {
                        ...old.hq,
                        specialization: name,
                    },
                }
            })
            queryClient.invalidateQueries({ queryKey: ["talents", gameId] })
        },
    })

    const { data, isLoading } = useQuery({ queryKey: ["specs_info", gameId], queryFn: () => specsInfos(gameId) })
    const count = data ? data[name] : 0

    return (
        <Panel variant="withBorder" justifyContent={"center"} alignItems={"center"} display={"flex"} flexDir={"column"}>
            {SPECIALIZATION_TO_SUPPORT[name] && (
                <Box m="2">
                    <GameIcon scale={2} name={SPECIALIZATION_TO_SUPPORT[name]!} />
                </Box>
            )}
            <Heading as="h3">{t(`specializations.${name}.title`)}</Heading>
            <Text textAlign={"center"}>{t(`specializations.${name}.description`)}</Text>
            <HelpText tooltip={`${count} player(s) in your faction chose this specialization.`} showHelpButton={false}>
                <>
                    {count}
                    <Icon as={RiUserFill} pt="1" />{" "}
                </>
            </HelpText>
            <Button
                isDisabled={name === SpecializationType.LOGISTIC}
                size="lg"
                isLoading={pick.isPending}
                onClick={() => {
                    pick.mutate()
                }}
                mt="8"
            >
                Choose
            </Button>
        </Panel>
    )
}

const PickASpecialization: FunctionComponent = () => {
    const specialization = Object.values(SpecializationType)

    return (
        <Box>
            <Text fontSize="xl" textAlign={"center"}>
                Choose a specialization.
            </Text>
            <Text textAlign={"center"}>
                Choose wisely. You can only have one specialization, and you will not be able to change it later.
            </Text>

            <SimpleGrid columns={[1, 1, 3]}>
                {specialization.map((spec) => (
                    <Specialization name={spec} key={spec} />
                ))}
            </SimpleGrid>
        </Box>
    )
}

export default PickASpecialization
