import { FunctionComponent } from "react"

interface LeaderPanelProps {}
const LeaderPanel: FunctionComponent<LeaderPanelProps> = ({}) => {
    return (
        <>
            <div></div>
        </>
    )
}

export default LeaderPanel
