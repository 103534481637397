import { Box, Stack, Text } from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import { FunctionComponent } from "react"
import { Navigate } from "react-router-dom"
import { getSessionStatus } from "../api/payments"
import Button from "../components/common/Button"
import Loading from "../components/common/Loading"

const CheckoutReturnPage: FunctionComponent = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const sessionId = urlParams.get("session_id")

    const { data } = useQuery({
        queryKey: ["checkout-session-status"],
        queryFn: () => getSessionStatus(sessionId!),
        refetchInterval: 3000,
        enabled: status !== "complete",
    })
    console.log(data)

    if (data?.status === "open") {
        return <Navigate to="/checkout" />
    }

    if (data?.status === "complete") {
        return (
            <Box p="4">
                {!data.is_premium && (
                    <Stack>
                        <Loading />
                        <Text>Your payment is being processed.</Text>
                    </Stack>
                )}
                {data.is_premium && (
                    <Text>
                        Thank you! A confirmation email will be sent to {data.customerEmail}.
                        <Button onClick={() => (window.location.href = "/")}>
                            Refresh the page to enjoy your premium account.
                        </Button>
                    </Text>
                )}
            </Box>
        )
    }

    return null
}

export default CheckoutReturnPage
