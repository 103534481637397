import { BaseResponse, authApi } from "./base"

interface createCheckoutSessionResponse {
    client_secret: string
    enabled: boolean
}

export async function createCheckoutSession(): Promise<createCheckoutSessionResponse> {
    const response = await authApi.post('payments/create-checkout-session')
    return response.data
}


interface SessionStatus {
    status: string
    customerEmail: string
    is_premium: boolean
}

export async function getSessionStatus(sessionId: string): Promise<SessionStatus> {
    const response = await authApi.get(`payments/session-status?session_id=${sessionId}`)
    return response.data
}


export async function becomePremium(): Promise<BaseResponse> {
    const response = await authApi.post('payments/become-premium')
    return response.data
}