import { Box } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { FunctionComponent } from "react"
import Chat from "../components/chat/Chat"
import { usePlayer } from "../hooks/query"
import { useStore } from "../store"
import { getIGChannels } from "../store/ChatStore"
import LeaderMessages from "../components/chat/LeaderMessages"

const ChatPage: FunctionComponent = observer(() => {
    const { chatStore } = useStore()
    const player = usePlayer()

    let channels: any = []
    if (player) {
        channels = getIGChannels(player)
        // channels.forEach((channel: any) => {
        //     chatStore.initChannel(channel.id, channel.name)
        // })
    }

    return (
        <Box p="0">
            <LeaderMessages />
            <Chat channels={channels} />
        </Box>
    )
})

export default ChatPage
