import { Box, FormControl, FormHelperText, FormLabel, HStack, Select, Stack } from "@chakra-ui/react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { FormEvent, FunctionComponent } from "react"
import { useFactions, useGameId } from "../../hooks/query"
import { HQInfo, Role, getHQInfo, roleLabels, updateRole } from "../../api/game"
import Button from "../common/Button"
import { LeaderRole } from "../../api/faction"
import LeaderMessage from "./LeaderMessage"

interface LeaderMessagesProps {}
const LeaderMessages: FunctionComponent<LeaderMessagesProps> = ({}) => {
    const gameId = useGameId()
    const queryClient = useQueryClient()
    const { data: faction } = useFactions()
    const ownFaction = faction?.find((f) => f.my_faction)

    return (
        <Box p="2">
            <LeaderMessage leaderRole={LeaderRole.Leader} message={ownFaction?.details?.messages.leader!} />
            <LeaderMessage leaderRole={LeaderRole.General} message={ownFaction?.details?.messages.general!} />
            <LeaderMessage leaderRole={LeaderRole.Diplomat} message={ownFaction?.details?.messages.diplomat!} />
        </Box>
    )
}

export default LeaderMessages
