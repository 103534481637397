import { Box, Icon, Portal, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { FunctionComponent, useEffect, useState } from "react"
import { BsChatSquareTextFill } from "react-icons/bs"
import { GiAchievement, GiCheckboxTree, GiWorld } from "react-icons/gi"
import { GoCheckbox } from "react-icons/go"
import { IoIosStats } from "react-icons/io"
import { RiHomeFill } from "react-icons/ri"
import { useLocation, useNavigate } from "react-router-dom"
import Lazy from "../components/common/Lazy"
import { useGameId, useIsUnlocked, usePlayer } from "../hooks/query"
import { useStore } from "../store"
import { getIGChannels } from "../store/ChatStore"
import { UnlockType } from "../types"
import AchievementPage from "./AchievementPage"
import ChatPage from "./ChatPage"
import FactionPage from "./FactionPage"
import HQ from "./HQ"
import Projects from "./Projects"
import SpecializationPage from "./SpecializationPage"
import Stats from "./Stats"
import World from "./World"

const ROUTES = {
    0: "hq",
    1: "world",
    2: "spec",
    3: "faction",
    4: "projects",
    5: "stats",
    6: "achievements",
    7: "chat",
}

// revert the routes to get the index from the url
const ROUTES_REVERT = Object.fromEntries(Object.entries(ROUTES).map(([k, v]) => [v, parseInt(k, 10)]))

const Menu: FunctionComponent = observer(() => {
    const showWorld = useIsUnlocked(UnlockType.UI_WORLD)
    const showProjects = useIsUnlocked(UnlockType.UI_PROJECTS)
    const showSpec = useIsUnlocked(UnlockType.UI_SPECIALIZATION)
    const { chatStore } = useStore()

    return (
        <TabList id="main-menu" boxShadow={"0px -1px 10px black"}>
            <Tab aria-label="Village">
                <Icon as={RiHomeFill} me="1" />
                <Box display={["none", "none", "inline-block"]} as="span">
                    Village
                </Box>
            </Tab>
            <Tab aria-label="World map" display={(showWorld && "flex") || "none"}>
                <Icon as={GiWorld} me="1" />
                <Box display={["none", "none", "inline-block"]}>World</Box>
            </Tab>
            <Tab aria-label="Specialization" display={(showSpec && "flex") || "none"}>
                <Icon as={GiCheckboxTree} me="1" />
                <Box display={["none", "none", "inline-block"]}>Spec</Box>
            </Tab>
            <Tab aria-label="Faction" display={(showWorld && "flex") || "none"}>
                <Icon as={GoCheckbox} me="1" />
                <Box display={["none", "none", "inline-block"]}>Faction</Box>
            </Tab>
            <Tab aria-label="Projects" display={(showProjects && "flex") || "none"}>
                <Icon as={GoCheckbox} me="1" />
                <Box display={["none", "none", "inline-block"]}>Projects</Box>
            </Tab>
            <Tab aria-label="Statistics" display={(showWorld && "flex") || "none"}>
                <Icon as={IoIosStats} me="1" />
                <Box display={["none", "none", "inline-block"]}>Stats</Box>
            </Tab>
            <Tab aria-label="Achievements">
                <Icon as={GiAchievement} me="1" />
                <Box display={["none", "none", "inline-block"]}>Achievements</Box>
            </Tab>
            <Tab aria-label="Chat">
                <Icon as={BsChatSquareTextFill} me="1" />
                <Box display={["none", "none", "inline-block"]}>Chat</Box>
                {chatStore.hasUnreadMessages() && (
                    <Box
                        display={"inline-block"}
                        borderRadius="50%"
                        width="8px"
                        height="8px"
                        bgColor="red.800"
                        position="relative"
                        top="-6px"
                        left="0"
                    ></Box>
                )}
            </Tab>
        </TabList>
    )
})

const Game: FunctionComponent = observer(() => {
    // set current tab according to url
    const { chatStore } = useStore()
    const { pathname } = useLocation()
    const player = usePlayer()
    const navigate = useNavigate()
    const [tabIndex, setTabIndex] = useState(ROUTES_REVERT[pathname] || 0)
    const gameId = useGameId()
    const handleTabsChange = (index: number) => {
        setTabIndex(index)
        navigate(`/play/${gameId}/${ROUTES[index as 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7]}/`)
    }

    const location = useLocation()
    useEffect(() => {
        const lastPart = location.pathname.split("/")[3]
        ROUTES_REVERT[lastPart] && setTabIndex(ROUTES_REVERT[lastPart])
    }, [location])

    // start chat even if we're not on the chat itself
    useEffect(() => {
        if (player) {
            const channels = getIGChannels(player)
            channels.forEach((channel) => {
                chatStore.initChannel(channel.id, channel.name)
            })
        }

        return () => {
            if (player) {
                const channels = getIGChannels(player)
                channels.forEach((c) => {
                    chatStore.leaveChannel(c.id)
                })
            }
        }
    }, [player?.userId])

    return (
        <Box>
            <Tabs index={tabIndex} variant="main" isLazy lazyBehavior="keepMounted" onChange={handleTabsChange}>
                {/* {!isMobile && <Menu />} */}

                <TabPanels>
                    <TabPanel>
                        <HQ />
                    </TabPanel>
                    <TabPanel p="0">
                        <World />
                    </TabPanel>
                    <TabPanel>
                        <SpecializationPage />
                    </TabPanel>
                    <TabPanel>
                        <FactionPage />
                    </TabPanel>
                    <TabPanel>
                        <Lazy active={tabIndex === 4}>
                            <Projects />
                        </Lazy>
                    </TabPanel>
                    <TabPanel>
                        <Stats />
                    </TabPanel>
                    <TabPanel>
                        <Lazy active={tabIndex === 6}>
                            <AchievementPage />
                        </Lazy>
                    </TabPanel>
                    <TabPanel padding="0">
                        <ChatPage />
                    </TabPanel>
                </TabPanels>

                {/* {isMobile && ( */}
                <Portal>
                    <Box position={"fixed"} bottom="0" left="0" bgColor="orange.300" w="full">
                        <Menu />
                    </Box>
                </Portal>
                {/* )} */}
            </Tabs>
        </Box>
    )
})

export default Game
