import { BuildingType, ResourceType, SpecializationType, SupportType } from "./types";

export const SPECIALIZATION_TO_SUPPORT = {
    [SpecializationType.ATTACK]: SupportType.KNIGHT,
    [SpecializationType.DEFENSE]: SupportType.GUARDIAN,
    [SpecializationType.LOGISTIC]: null,
}

export const MAX_PLAYERS_IN_GROUP = 4
export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL
export const WS_BASE_URL = import.meta.env.VITE_WS_BASE_URL

export const MINIMAL_SUPPORT_TO_INVADE = 10

export const WORLD_SIZE = 50

// export const BUILDINGS: Building[] = [
//     {
//         name: BuildingType.Woodcutter,
//         label: "Woodcutter",
//         cost: {
//             [ResourceType.Wood]: 10,
//             [ResourceType.Stone]: 10
//         }
//     },
//     {
//         name: BuildingType.Quarry,
//         label: "Quarry",
//         cost: {
//             [ResourceType.Wood]: 25,
//             [ResourceType.Stone]: 25
//         }
//     },
//     {
//         name: BuildingType.Barracks,
//         label: "Barrack",
//         cost: {
//             [ResourceType.Wood]: 100,
//             [ResourceType.Stone]: 50
//         }
//     }
// ]

