import { Factions } from '../types'
import { BaseResponse, anonApi, authApi } from './base'
import Axios from 'axios'

interface LoginStatus {
    available: boolean
    accountsLeft: number
}

export async function getLoginStatus(): Promise<LoginStatus> {
    const response = await authApi.get('players/login_status')
    return response.data
}


interface AnonLoginResponse {
    status: "error" | "ok"
    api_token?: string
}

/** 
 * "register" user to backend.
 * If user does not exists, create it (backend job), else return the token
 */
export async function anonLogin() {
    // connect user to backend
    return await anonApi.post<AnonLoginResponse>('players/anon_login', {})
}

export interface Player {
    gameId: number
    userId: number
    username: string
    isAdmin: boolean
    isPremium: boolean
    temporary: boolean
    faction: Factions
    hasPendingInvitation: boolean
}
export async function getPlayer(gameId: number | undefined): Promise<Player> {
    const params = gameId ? { game_id: gameId } : {}
    // get player info
    const player = await authApi.get(`/players/get`, { params })
    return player.data
}


export interface PlayerIn {
    username?: string
}

export async function updatePlayer(data: PlayerIn) {
    return await authApi.post('players/update', data)
}


export interface LeaderboardPlayers {
    name: string
    faction: Factions
    hqLevel: number
    sentSoldiers: number
    sentWorkers: number
    casesCaptured: number
}
export async function getLeaderboardPlayers(gameId: number): Promise<LeaderboardPlayers[]> {
    const players = await authApi.get(`/game/${gameId}/leaderboard`)
    return players.data
}

export async function getConnectedPlayers(gameId: number): Promise<number> {
    const players = await authApi.get(`/game/${gameId}/connected`)
    return players.data
}

export interface PlayerProfile {
    player: {
        userId: number
        username: string
    }
    playerStats: {
        gamesPlayed: number
        gamesWon: number
        gamesSecond: number
        gamesThird: number
        bestPlayer: number
    }
    gameStats?: {
        faction: Factions
        hqLevel: number
        sentSoldiers: number
        sentWorkers: number
        casesCaptured: number
    }
}
export async function getPlayerProfile(username: string): Promise<PlayerProfile> {
    const player = await authApi.get(`players/get/${username}`)
    return player.data
}

export async function getPlayerTPAccounts(): Promise<string[]> {
    /** get player third parties accounts */
    const accounts = await authApi.get('players/tp_accounts')
    return accounts.data
}

export interface PlayerSearch {
    id: number
    username: string
}

interface searchUserParams {
    gameId?: number
    querySearch: string
    faction?: Factions
}
export async function searchUser({ gameId, faction, querySearch }: searchUserParams): Promise<PlayerSearch[]> {
    console.log("searchUser", gameId, faction, querySearch)
    const players = await authApi.get('players/search', { params: { game_id: gameId, faction, query: querySearch } })
    return players.data
}


export interface Invitation {
    id: number
    leader: string
}

export async function getInvitations(): Promise<Invitation[]> {
    const invitation = await authApi.get('players/group/invitations')
    return invitation.data
}

export interface GroupInfo {
    id: number
    leader: string
    isLeader: boolean
    members: {
        id: number
        username: string
        pending: boolean
        isLeader: boolean
    }[]
}
export async function getGroup(): Promise<GroupInfo | null> {
    const group = await authApi.get('players/group')
    return group.data
}


export async function groupInvite(playerId: number): Promise<BaseResponse> {
    const response = await authApi.post('players/group/invite', { id: playerId })
    return response.data
}

export async function groupKick(playerId: number): Promise<BaseResponse> {
    const response = await authApi.post('players/group/kick', { id: playerId })
    return response.data
}

export async function groupLeave(): Promise<BaseResponse> {
    const response = await authApi.post('players/group/leave')
    return response.data
}

export async function groupAccept(invitationId: number): Promise<BaseResponse> {
    const response = await authApi.post('players/group/accept', { id: invitationId })
    return response.data
}

export async function groupDecline(invitationId: number): Promise<BaseResponse> {
    const response = await authApi.post('players/group/decline', { id: invitationId })
    return response.data
}
