import { Box, Center, Heading, Stack, Text } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { FunctionComponent, useEffect } from "react"
import LoginButtons from "../components/auth/LoginButtons"
import Cookies from "js-cookie"
import { useStore } from "../store"
import { useQuery } from "@tanstack/react-query"
import { getPlayerTPAccounts } from "../api/player"
const COOKIE_DOMAIN = import.meta.env.VITE_COOKIE_DOMAIN

const Register: FunctionComponent = observer(() => {
    const { userStore } = useStore()
    useEffect(() => {
        Cookies.set("api_token", userStore.apiToken!, { expires: 1, domain: COOKIE_DOMAIN })
    }, [])

    const { data, isLoading } = useQuery({ queryKey: ["tp_accounts"], queryFn: getPlayerTPAccounts })

    return (
        <Box p="4">
            <Stack spacing={3}>
                <Heading>Register your account</Heading>

                {userStore.player?.temporary && (
                    <Text>Please log to keep track of your progress. You will not lose your current progress.</Text>
                )}
                {!userStore.player?.temporary && <Text>You can link additional third party accounts.</Text>}
                {data?.length && <Text fontWeight={"bold"}>Your account is already linked with {data.join(", ")}</Text>}
                {!isLoading && <LoginButtons hide={data} />}
            </Stack>
        </Box>
    )
})

export default Register
