import { Box, Divider, HStack, Heading, Stack, Text } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import Progress from "../../common/ProgressWithText"
import { UISpendResources } from "../../../hooks/utils"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useStore } from "../../../store"
import { useConfig, useGameId, useIsUnlocked, useResources } from "../../../hooks/query"
import Button from "../../common/Button"
import GameIcon, { GameIconType } from "../../common/GameIcon"
import { ResourceType, UnlockType } from "../../../types"
import { fortify } from "../../../api/world"
import HelpText from "../../common/HelpText"

interface TileFortificationProps {}
const TileFortification: FunctionComponent<TileFortificationProps> = () => {
    const queryClient = useQueryClient()
    const { mapStore } = useStore()
    const selectedTile = mapStore.selectedTile
    const resources = useResources()

    const tileInfo = mapStore.getTileInfo(selectedTile!.x, selectedTile!.y)
    const isUnlocked = useIsUnlocked(UnlockType.UI_TILE_FORTIFICATION)
    const gameId = useGameId()

    // send workers
    const work = useMutation({
        mutationFn: (workers: number) => fortify(gameId, selectedTile!.x, selectedTile!.y, workers),
        onMutate: (workers) => {
            UISpendResources({ gameId, resources: { workers } })
        },
    })

    if (!tileInfo) return <Box></Box>
    return (
        <Box>
            <HStack alignItems={"center"} justifyContent={"space-between"}>
                <HStack>
                    <GameIcon name={GameIconType.FORTIFICATION} />
                    <Heading size="md">
                        <HelpText tooltip="Fortification gives you a defense bonus (penalty to enemy attacks). Fortification are destroyed if an enemy faction capture this terrain.">
                            Fortification
                        </HelpText>
                    </Heading>
                </HStack>
                <Text fontSize="xs">lvl {tileInfo.fortification.level}/4</Text>
            </HStack>

            <Text>+{tileInfo.fortification.bonus}% defense</Text>

            {tileInfo.fortification.level < 4 && tileInfo.fortification.allowed && isUnlocked && (
                <>
                    <Progress value={tileInfo.fortification.currentGoalWorkers} total={tileInfo.fortification.goal}>
                        {tileInfo.fortification.currentGoalWorkers} / {tileInfo.fortification.goal}
                    </Progress>
                    <HStack gap="0" justifyContent={"center"}>
                        <Button
                            onClick={() => {
                                work.mutate(1)
                            }}
                            isDisabled={resources.workers < 1}
                            margin="0.5"
                            p="2"
                            rightIcon={<GameIcon name={ResourceType.WORKER} />}
                        >
                            +1
                        </Button>
                        <Button
                            onClick={() => {
                                work.mutate(10)
                            }}
                            isDisabled={resources.workers < 10}
                            margin="0.5"
                            p="2"
                            rightIcon={<GameIcon name={ResourceType.WORKER} />}
                        >
                            +10
                        </Button>
                        <Button
                            onClick={() => {
                                work.mutate(100)
                            }}
                            isDisabled={resources.workers < 100}
                            margin="0.5"
                            p="2"
                            rightIcon={<GameIcon name={ResourceType.WORKER} />}
                        >
                            +100
                        </Button>
                    </HStack>
                </>
            )}
        </Box>
    )
}

export default TileFortification
