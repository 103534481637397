import { action, autorun, has, makeObservable, observable, runInAction, toJS } from "mobx"
import BaseStore from "./BaseStore"
import RootStore from "./RootStore"
import { FACTIONS_LIST, Factions } from "../types"
import ReconnectingWebSocket from "reconnecting-websocket"
import { WS_BASE_URL } from "../config"
import { matchPath } from "react-router-dom"
import { Player } from "../api/player"

export interface Message {
    id: number
    player: {
        id: number
        username: string
        faction: Factions
        leader_role: string
        premium: boolean
    }
    content: string
    created_at: number // timestamp
}

export interface Channel {
    id: string
    name: string
    socket: ReconnectingWebSocket
    messages: Message[]
    loaded: boolean
    lastReadMessage: number | null
    hasUnreadMessages: boolean
}

export default class ChatStore extends BaseStore {

    channels: { [key: string]: Channel } = {} /** list of channels */
    // hasUnreadMessages = false
    activeChannel: number = 0 /** selected channel index */
    activeChannelId: string = "" /** selected channel id */
    isChatVisible = false


    constructor(rootStore: RootStore) {
        super(rootStore)
        makeObservable(this, {
            channels: observable,
            hasUnreadMessages: observable,
            activeChannel: observable,
            activeChannelId: observable,
            isChatVisible: observable,
            initChannel: action,
            leaveChannel: action,
            fetchMessages: action,
            markAsRead: action,
            setActiveChannel: action,
            setIsChatVisible: action,
        })

        // autorun(() => {
        //     const gameMatch = matchPath({ path: "/play/:gameId" }, window.location.pathname)
        //     if (gameMatch?.params.gameId) {
        //         this.initGameChannels()
        //     }
        //     // const lobbyMatch = matchPath({ path: "/games/:gameId" }, window.location.pathname)
        //     // if (lobbyMatch?.params.gameId) {
        //     //     this.initLobbyChannel(lobbyMatch?.params.gameId)
        //     // }

        // })
    }

    initChannel(channelId: string, channelName: string) {
        if (has(this.channels, channelId)) return
        const storage = localStorage.getItem("chat")
        const readInfos = storage ? JSON.parse(storage) : {}
        const channel = {
            id: channelId,
            name: channelName,
            socket: new ReconnectingWebSocket(`${WS_BASE_URL}chat/${channelId}`, ['Token', `${this.rootStore.userStore.apiToken}`]),
            loaded: false,
            messages: [],
            lastReadMessage: readInfos[channelId] || null,
            hasUnreadMessages: false
        }
        this.channels[channelId] = channel

        channel.socket.onopen = () => {
            this.fetchMessages(channel.id)
        }
    }

    leaveChannel(channelId: string) {
        const channel = this.channels[channelId]
        if (channel) {
            channel.socket.close()
            delete this.channels[channelId]
        }
    }

    getChannel(channelId: string) {
        return this.channels[channelId]
    }

    fetchMessages(channelId: string) {
        const channel = this.getChannel(channelId)
        if (channel!.socket) {
            channel!.socket.onmessage = (e) => {
                const data = JSON.parse(e.data)
                const type = data.type
                if (type == "initial" && channel!.loaded) return
                const newMessages = data.messages as Message[]
                runInAction(() => {

                    if (type == "initial") {
                        channel!.messages = newMessages
                        channel!.loaded = true
                        // check if we have recent messages
                        const lastRead = channel!.lastReadMessage
                        if (newMessages.length && newMessages[newMessages.length - 1].id > lastRead!) {
                            channel!.hasUnreadMessages = true
                        }
                    }
                    else {
                        channel!.messages = [...channel!.messages, ...newMessages]
                        // this.hasUnreadMessages = true
                        if (this.getActiveChannel().id == channel!.id && this.isChatVisible) {
                            channel!.hasUnreadMessages = false
                            this.markAsRead(channelId, true)
                        }
                        else {
                            channel!.hasUnreadMessages = true
                        }
                    }
                })

            }
        }
    }

    markAsRead(channelId: string | undefined, removeLastRead = false) {
        if (channelId) {
            const channel = this.getChannel(channelId)
            channel!.hasUnreadMessages = false
            if (removeLastRead && channel!.messages.length)
                channel!.lastReadMessage = channel!.messages[channel!.messages.length - 1].id
            this.storeToLocalStorage()
        }
        // else
        // this.hasUnreadMessages = false

    }

    setActiveChannel(index: number, id: string) {
        if (this.channels[id]) {
            this.activeChannel = index
            this.activeChannelId = id
            this.channels[id].hasUnreadMessages = false
        }
    }

    getActiveChannel() {
        console.log("this.activeChannelId", this.activeChannelId)
        return this.channels[this.activeChannelId]
    }

    /** send a message to a channel */
    sendMessage(channelId: string, message: string) {
        const channel = this.getChannel(channelId)
        if (channel!.socket) {
            channel!.socket.send(JSON.stringify({ message }))
        }
    }

    hasUnreadMessages() {
        // check every channels to see if there are unread messages
        return Object.values(this.channels).some(c => c.hasUnreadMessages)
    }

    setIsChatVisible(visible: boolean) {
        this.isChatVisible = visible
    }

    storeToLocalStorage() {
        // create a dictionary of channels with last read message
        const storage = localStorage.getItem("chat")
        const oldValue = storage ? JSON.parse(storage) : {}

        const channels: any = {}
        Object.values(this.channels).forEach(c => {
            channels[c.id] = c.lastReadMessage
        })



        // const channels = Object.values(this.channels).map(c => {
        //     return {
        //         id: c.id,
        //         lastUnreadMessage: c.lastReadMessage,
        //     }
        // })
        localStorage.setItem("chat", JSON.stringify({ ...oldValue, ...channels }))
    }


}



export function getIGChannels(player: Player): { id: string, name: string }[] {
    const gameId = player.gameId
    const enemyFactions = FACTIONS_LIST.filter((f) => f !== player?.faction)

    const channels = [
        {
            id: `${gameId}_general`,
            name: "General",
        },
    ]
    if (player) {
        channels.push({
            id: `${gameId}_${player.faction}`,
            name: player.faction,
        })
        enemyFactions.forEach((faction) => {
            const factions = [player.faction, faction].sort().join("_")
            console.log(factions)
            channels.push({
                id: `${gameId}_${factions}`,
                name: `With ${faction}`,
            })
        })
    }
    return channels
}