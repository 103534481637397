import { Factions } from "../types"
import { BaseResponse, authApi } from "./base"


export enum GameStatus {
    PLAYING = "PLAYING",
    COMPLETED = "COMPLETED",
    LOBBY = "LOBBY"
}

export enum GameMode {
    STANDARD = "STANDARD",
    SHORT = "SHORT",
    FLASH = "FLASH"
}


export const GameModeInfo = {
    [GameMode.STANDARD]: {
        name: "Standard",
        description: "The standard game mode. Last approximately 1 week.",
    },
    [GameMode.SHORT]: {
        name: "Short",
        description: "A shorter game mode. Last approximately 1 days.",
    },
    [GameMode.FLASH]: {
        name: "Flash",
        description: "A very short game mode. Last approximately 1 hours.",
    },
}


export interface Game {
    id: number
    status: GameStatus
    type: GameMode
    winner: Factions
    winning: Factions
    victoryDate: Date
    winningDate: Date
    points: {
        [Factions.RED]: number
        [Factions.BLUE]: number
        [Factions.GREEN]: number
        [Factions.YELLOW]: number
    }
    numberOfPlayers: number
    canJoinAfterStart: boolean
    isOpen: boolean
    participating: boolean
    maxPlayers: number
    maxGroupSize: number
}

export async function getGame(gameId: number): Promise<Game> {
    const gameInfo = await authApi.get(`games/${gameId}/get`)
    return gameInfo.data
}

export async function getGames(): Promise<Game[]> {
    const gameInfo = await authApi.get('games/list')
    return gameInfo.data
}

export async function joinGame(gameId: number): Promise<BaseResponse> {
    const gameInfo = await authApi.post(`games/${gameId}/join`)
    return gameInfo.data
}

export async function leaveLobby(gameId: number): Promise<BaseResponse> {
    const gameInfo = await authApi.post(`games/${gameId}/leave`)
    return gameInfo.data
}