import { Box, Container, Flex } from "@chakra-ui/react"
import { FunctionComponent, useEffect } from "react"
import { Outlet, useNavigate, useParams } from "react-router-dom"
import { useGame, useGameId, useHQInfo } from "../../hooks/query"
import { useIsMobile } from "../../hooks/utils"
import { QuestBubble } from "../quests/QuestBubble"
import Header from "./Header"

const GameRoot: FunctionComponent = () => {
    const mobile = useIsMobile()
    const { data: game } = useGame()
    const { data: hqInfo } = useHQInfo()
    const navigate = useNavigate()

    const gameId = useGameId()

    useEffect(() => {
        if (game?.status === "COMPLETED") {
            navigate(`/games/${gameId}`)
        }
    }, [game?.status])

    return (
        <Flex direction="column">
            <Container flex="1" p={mobile ? 0 : 2} maxW="100%">
                <Box role="presentation" color="on-accent" minH="lg" bg="bg-accent">
                    <Outlet />
                </Box>
            </Container>
            {hqInfo?.hasActiveQuest && <QuestBubble />}
        </Flex>
    )
}

export default GameRoot
