import { FunctionComponent } from "react"
import Number from "./Number"

interface DurationProps {
    value: number /** in seconds */
}
const Duration: FunctionComponent<DurationProps> = ({ value }) => {
    let unit = "seconds"
    let properValue = value
    if (value) {
        if (value <= 3600) {
            unit = "minutes"
            properValue = value / 60
        } else {
            unit = "hours"
            properValue = value / 3600
        }
    }

    return (
        <>
            <Number value={properValue} /> {unit}
        </>
    )
}

export default Duration
