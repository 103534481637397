import { Box, Center, Flex, HStack, Heading, Stack, Text } from "@chakra-ui/react"
import { FunctionComponent, useState } from "react"
import { useEffects, useGameId, useHQInfo } from "../../hooks/query"
import { Talent as ITalent, pickTalent, useTalents } from "../../api/specialization"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { BuildingType } from "../../types"
import ModList from "../resources/ModList"
import { clamp } from "../../utils"
import Progress from "../common/ProgressWithText"
import { useTranslation } from "react-i18next"
import { SPECIALIZATION_TO_SUPPORT } from "../../config"
import GameIcon from "../common/GameIcon"
import Loading from "../common/Loading"
import { useIsDebug } from "../../hooks/utils"
import Button from "../common/Button"
import { debugAction } from "../../api/game"

interface TalentProps extends ITalent {
    unlocked: boolean
    remaining: number
}
const Talent: FunctionComponent<TalentProps> = ({
    name,
    maxPoints: maxPoints,
    tiers,
    points,
    unlocked,
    remaining,
    baseEffects,
    global,
}) => {
    const queryClient = useQueryClient()
    const gameId = useGameId()
    const assigned = tiers === 0 ? 1 : points
    const isDisabled = !unlocked || assigned >= maxPoints || remaining <= 0
    const [isHover, setHover] = useState(false)
    const { t } = useTranslation()

    const pick = useMutation({
        mutationFn: () => pickTalent(gameId, name),

        onMutate: () => {
            queryClient.setQueryData(["talents", gameId], (old: ITalent[] | undefined) => {
                if (!old) return
                return old.map((talent) => {
                    if (talent.name === name) {
                        return {
                            ...talent,
                            points: talent.points + 1,
                        }
                    }
                    return talent
                })
            })
        },
        onError: () => {
            queryClient.invalidateQueries({ queryKey: ["talents", gameId] })
        },
        onSuccess: (data) => {
            if (data["error"]) {
                queryClient.invalidateQueries({ queryKey: ["talents", gameId] })
            }
        },
    })

    return (
        <Box
            bgColor={isDisabled ? "red.800" : "green.800"}
            opacity={!unlocked ? 0.5 : 1}
            transition={"all 0.2s ease-in-out"}
            _hover={{
                bgColor: isDisabled ? "red.800" : "green.700",
                cursor: "pointer",
                boxShadow: !isDisabled && "2px 2px 2px 1px rgba(0, 0, 0, 0.5)",
                transform: !isDisabled && "translate3d(-2px,-2px,0)",
            }}
            border="2px solid"
            boxShadow={"md"}
            borderRadius={"sm"}
            borderStyle="outset"
            borderColor="orange.600"
            onClick={() => {
                if (!isDisabled && tiers !== 0) pick.mutate()
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <Box>
                <Heading as="h4" size="sm" borderBottom="1px solid" borderColor="orange.600" p="2">
                    {t(`talents.${name}.title`)}
                </Heading>
                <Box p="2">
                    {!baseEffects && <Text>{t(`talents.${name}.description`)}</Text>}
                    {baseEffects && (
                        <ModList
                            value={baseEffects}
                            level={clamp(isHover ? assigned + 1 : assigned, 1, maxPoints)}
                            global={global}
                        />
                    )}
                </Box>
            </Box>
            <Progress value={assigned} total={maxPoints} mb="-6px" h="25px" />
            {/* <Text>points : {assigned} / {maxPoints}</Text> */}
        </Box>
    )
}

interface TalentsRowProps {
    row: number /** row number */
    unlockedAt: number /** minimum number of points to spend to unlock this row */
    talents: ITalent[]
    spent: number /** spent points */
    remaining: number /** remaining points left */
}
const TalentsRow: FunctionComponent<TalentsRowProps> = ({ row, unlockedAt, talents, spent, remaining }) => {
    const unlocked = spent >= unlockedAt

    return (
        <Box>
            <Center
                bgColor={unlocked ? "green.800" : "red.800"}
                border="2px solid"
                borderColor="orange.500"
                boxShadow={"md"}
            >
                <Text>Tiers {row}</Text>
            </Center>
            <Flex justifyContent="space-around" m="4" wrap="wrap">
                {talents.map((talent) => (
                    <Talent {...talent} key={talent.name} unlocked={unlocked} remaining={remaining} />
                ))}
            </Flex>
        </Box>
    )
}

const Specialization: FunctionComponent = () => {
    const queryClient = useQueryClient()
    const gameId = useGameId()
    const { data } = useHQInfo()
    const specialization = data?.hq.specialization
    const { data: effects } = useEffects()

    const { data: talents } = useTalents()

    const debug = useMutation({
        mutationFn: (params: any) => debugAction(gameId, params),
        onSuccess: () => queryClient.invalidateQueries(),
    })
    const showDebug = useIsDebug()

    if (!effects || !talents || !specialization) return <Loading />
    const talentPoints = effects?.talent.talent.total

    let spent = 0
    let remaining = talentPoints
    if (talents) {
        spent = talents.reduce((acc, talent) => acc + talent.points, 0)
        remaining = remaining - spent
    }

    return (
        <Box>
            <Center>
                <Stack>
                    <HStack>
                        <Heading textAlign={"center"}>{specialization}</Heading>
                        {SPECIALIZATION_TO_SUPPORT[specialization] && (
                            <Box m="2">
                                <GameIcon scale={2} name={SPECIALIZATION_TO_SUPPORT[specialization]!} />
                            </Box>
                        )}
                    </HStack>
                    <Text>Remaining points : {remaining}</Text>

                    {showDebug && (
                        <Button
                            onClick={() => {
                                debug.mutate({ name: "reset_spec" })
                            }}
                        >
                            Reset specs
                        </Button>
                    )}
                </Stack>
            </Center>

            {talents && (
                <Box m="4">
                    <TalentsRow
                        row={0}
                        unlockedAt={0}
                        talents={talents.filter((talent) => talent.tiers === 0)}
                        spent={spent}
                        remaining={remaining}
                    />
                    <TalentsRow
                        row={1}
                        unlockedAt={0}
                        talents={talents.filter((talent) => talent.tiers === 1)}
                        spent={spent}
                        remaining={remaining}
                    />
                    <TalentsRow
                        row={2}
                        unlockedAt={5}
                        talents={talents.filter((talent) => talent.tiers === 2)}
                        spent={spent}
                        remaining={remaining}
                    />
                    <TalentsRow
                        row={3}
                        unlockedAt={10}
                        talents={talents.filter((talent) => talent.tiers === 3)}
                        spent={spent}
                        remaining={remaining}
                    />
                </Box>
            )}
        </Box>
    )
}

export default Specialization
