import { Box } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { FunctionComponent } from "react"
import Players from "../components/faction/Players"
import Surveys from "../components/faction/Surveys"

const FactionPage: FunctionComponent = observer(() => {
    return (
        <Box p="0">
            <Surveys />
            <Players />
        </Box>
    )
})

export default FactionPage
