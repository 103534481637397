import { Box, Card, CardBody, CardHeader, Center, Flex, HStack, Heading, Select, Text } from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import { FunctionComponent, useState } from "react"
import { getLeaderboardPlayers } from "../../api/player"
import Loading from "../common/Loading"
import Progress from "../common/ProgressWithText"
import { useGameId } from "../../hooks/query"

type sortKeyType = "hqLevel" | "sentSoldiers" | "sentWorkers" | "casesCaptured"

interface LeaderboardProps {}

export const Leaderboard: FunctionComponent<LeaderboardProps> = ({}) => {
    const gameId = useGameId()
    const { data: players, isLoading } = useQuery({
        queryKey: ["players", gameId],
        queryFn: () => getLeaderboardPlayers(gameId),
    })

    const [sortKey, setSortKey] = useState<sortKeyType>("hqLevel")

    const sorted = players
        ?.sort((a, b) => (a[sortKey] > b[sortKey] ? -1 : b[sortKey] > a[sortKey] ? 1 : 0))
        .slice(0, 100)

    return (
        <Card variant="elevated">
            <CardHeader>
                <Heading size="md">Leaderboard</Heading>
            </CardHeader>

            <CardBody>
                {isLoading && (
                    <Center>
                        <Loading />
                    </Center>
                )}
                <Box height={400} overflowY={"scroll"}>
                    <Select
                        onChange={(e) => setSortKey(e.target.value as sortKeyType)}
                        color="black"
                        bgColor="orange.300"
                        borderRadius={"sm"}
                        border="orange.900"
                        defaultValue="hqLevel"
                    >
                        <option value="hqLevel">HQ level</option>
                        <option value="sentSoldiers">Sent soldiers</option>
                        <option value="sentWorkers">Sent workers</option>
                        <option value="casesCaptured">Tiles captured</option>
                    </Select>
                    {sorted &&
                        sorted.map((player, index) => {
                            return (
                                <Flex key={player.name} justifyContent="space-between" alignItems="center">
                                    <Progress
                                        value={player[sortKey]}
                                        total={sorted[0][sortKey]}
                                        h="50px"
                                        progressColor={player.faction}
                                        width="full"
                                    >
                                        <HStack justifyContent={"space-between"}>
                                            <Text>
                                                {index + 1}. {player.name}
                                            </Text>
                                            <Text>{player[sortKey]}</Text>
                                        </HStack>
                                    </Progress>
                                </Flex>
                            )
                        })}
                </Box>
            </CardBody>
        </Card>
    )
}
