import { Box, Button } from "@chakra-ui/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { FunctionComponent } from "react"
import { Choice as ChoiceInterface, Survey, vote } from "../../api/faction"
import { useGameId, usePlayer } from "../../hooks/query"
import Progress from "../common/ProgressWithText"

interface ChoiceProps extends ChoiceInterface {
    surveyId: number
    pickUsers: boolean
    totalVotes: number
    voted: boolean
}
const Choice: FunctionComponent<ChoiceProps> = ({ surveyId, id, bonus, name, votes, totalVotes, voted, pickUsers, winner }) => {
    const queryClient = useQueryClient()
    const gameId = useGameId()
    const player = usePlayer()
    const canVote = !pickUsers || id != player?.userId
    const voteMutation = useMutation({
        mutationFn: () => vote(gameId, surveyId, id),
        onSuccess: () => {
            // optimisic update to update the "voted" property
            // other stats (like votes count) will be updated by the faction socket
            queryClient.setQueryData(["surveys", gameId], (oldData: Survey[] | undefined) => {
                // find relevant survey and update it
                const survey = oldData?.find((s) => s.id == surveyId)
                if (survey) {
                    return oldData?.map((s) => {
                        if (s.id == surveyId) {
                            return {
                                ...s,
                                voted: id,
                            }
                        }
                        return s
                    })
                }
            })
        },
        onError(error, variables, context) {
            queryClient.invalidateQueries({ queryKey: ["surveys", gameId] })
        },
    })

    return (
        <Progress
            value={votes}
            total={totalVotes}
            height={8}
            onClick={() => {
                if (canVote) voteMutation.mutate()
            }}
            cursor={canVote ? "pointer" : "auto"}
            border={winner ? "2px solid green" : "none"}
            _hover={{
                border: "2px solid",
                borderColor: "orange.600"
            }}
        >
            {name} - {votes} {voted && "✔"}
        </Progress>
    )
}

export default Choice
