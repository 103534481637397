import { Box, HStack, Heading, SimpleGrid, Stack, Text } from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import { FunctionComponent } from "react"
import { getSurveys, useSurveys } from "../../api/faction"
import { useFaction, useGameId } from "../../hooks/query"
import Survey from "./Survey"
import Player from "./Player"
import { default as RoleComponent } from "../common/Role"
import { Role } from "../../api/game"
import { getEntries } from "../../utils"
import Panel from "../common/Panel"
import RoleSelect from "./RoleSelect"

interface PlayersProps {}
const Players: FunctionComponent<PlayersProps> = ({}) => {
    const gameId = useGameId()
    const data = useFaction()

    if (!data) {
        return null
    }

    // count the number of players in each role
    const roleCounts: { [key in Role]?: number } = {}
    data.details?.members?.forEach((member) => {
        if (!roleCounts[member.role]) {
            roleCounts[member.role] = 0
        }
        roleCounts[member.role]!++
    })
    // sort roles by count
    Object.entries(roleCounts).sort((a, b) => b[1] - a[1])

    // sort members by name
    data.details?.members?.sort((a, b) => a.name.localeCompare(b.name))

    return (
        <SimpleGrid columns={[1, 2]}>
            <Panel>
                <RoleSelect />
                <Stack mt="4">
                    <Heading as="h3" size="md">
                        Your faction roles
                    </Heading>
                    {getEntries(roleCounts).map(([role, count]) => (
                        <HStack key={role}>
                            <RoleComponent role={role} />
                            <Text> : {count}</Text>
                        </HStack>
                    ))}
                </Stack>
            </Panel>
            <Panel>
                <Heading as="h3" size="md" mb="2">
                    Players
                </Heading>
                <Box maxHeight="500px" overflow="auto">
                    {data.details?.members?.map((member) => (
                        <Player key={member.id} {...member} />
                    ))}
                </Box>
            </Panel>
        </SimpleGrid>
    )
}

export default Players
