import { Box, BoxProps } from "@chakra-ui/react"
import { FunctionComponent } from "react"

interface ProgressProps extends BoxProps {
    value: number
    children?: React.ReactNode
    showText?: boolean
    progressColor?: string
    total?: number
}
const Progress: FunctionComponent<ProgressProps> = ({
    value,
    total,
    children,
    progressColor = "orange.400",
    showText = true,
    height = "20px",
    ...boxProps
}) => {
    let progress = value
    if (total) progress = Math.min(Math.round((value / total) * 100), 100)

    return (
        <Box
            display="inline-block"
            as="span"
            position="relative"
            w="full"
            minH={height}
            borderColor="orange.600"
            borderWidth={1}
            {...boxProps}
        >
            <Box
                w={progress + "%"}
                top="0"
                right="0"
                left="0"
                bottom="0"
                position={"absolute"}
                bgColor={progressColor}
                transition="width 0.3s ease"
            ></Box>
            {children && (
                <Box
                    position="absolute"
                    display="flex"
                    justifyContent={"center"}
                    alignItems={"center"}
                    h="100%"
                    w="full"
                >
                    {children}
                </Box>
            )}
            {total && !children && showText ? (
                <Box
                    position="absolute"
                    display="flex"
                    justifyContent={"center"}
                    alignItems={"center"}
                    w="full"
                    h="full"
                >
                    {value} / {total}
                </Box>
            ) : null}
        </Box>
    )
}

export default Progress
