
import { FunctionComponent } from "react"
import Button from "../common/Button"
import { BsDiscord, BsGoogle, BsSteam } from "react-icons/bs"

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL

interface LoginButtonsProps {
    hide?: string[] /** hide some buttons */
}

const LoginButtons: FunctionComponent<LoginButtonsProps> = ({hide=[]}) => {
    return (
        <>
            {!hide.includes('steam') && <Button colorScheme="black" leftIcon={<BsSteam />} w={"300px"} onClick={() => window.location.href = `${API_BASE_URL}login/steam`} title="Steam">Sign in with Steam</Button>}
            {!hide.includes('discord') && <Button colorScheme="blue" leftIcon={<BsDiscord />} w={"300px"} onClick={() => window.location.href = `${API_BASE_URL}login/discord`} title="Discord">Sign in with Discord</Button>}
            {!hide.includes('google-oauth2') && <Button colorScheme="white" color="black" leftIcon={<BsGoogle />} w={"300px"} onClick={() => window.location.href = `${API_BASE_URL}login/google-oauth2`} title="Google">Sign in with Google</Button>}
        </>

    )
}

export default LoginButtons

