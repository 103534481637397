import {
    Box,
    FormControl,
    FormHelperText,
    FormLabel,
    HStack,
    Heading,
    IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Stack,
    Text,
    Textarea,
} from "@chakra-ui/react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { FormEvent, FunctionComponent, useState } from "react"
import { useFactions, useGameId, useLeaderRole } from "../../hooks/query"
import { HQInfo, Role, getHQInfo, roleLabels, updateRole } from "../../api/game"
import Button from "../common/Button"
import { LeaderRole, updateMessage } from "../../api/faction"
import { Icon } from "chakra-ui"
import { MdEdit } from "react-icons/md"

interface LeaderMessageProps {
    leaderRole: LeaderRole
    message: string
}
const LeaderMessage: FunctionComponent<LeaderMessageProps> = ({ leaderRole, message }) => {
    const [editMode, setEditMode] = useState(false)
    const gameId = useGameId()
    const queryClient = useQueryClient()
    const { data: faction } = useFactions()
    const playerRole = useLeaderRole()
    const hasRole = leaderRole === playerRole
    console.log(hasRole)

    const mutation = useMutation({
        mutationFn: (message: string) => updateMessage(gameId, leaderRole, message),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["factions", gameId] })
            setEditMode(false)
        },
    })

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const formData = new FormData(e.currentTarget)
        mutation.mutate(formData.get("message") as string)
    }

    return (
        <Box mb="2">
            <HStack justify={"flex-start"}>
                <Heading as="h3" size="sm">
                    {message ? `Message from ${leaderRole}` : `No message from ${leaderRole} yet`}
                </Heading>
                {hasRole && (
                    <IconButton onClick={() => setEditMode(!editMode)} icon={<MdEdit />} aria-label="Edit" size="sm" variant="ghost" />
                )}
            </HStack>
            {message && (
                <Text
                    fontSize="sm"
                    lineHeight={1.1}
                    dangerouslySetInnerHTML={{ __html: message.replace("\n", "<br/>") }}
                ></Text>
            )}
            {hasRole && (
                <>
                    <Modal isOpen={editMode} onClose={() => setEditMode(false)}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Edit message</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <form action="post" onSubmit={(e) => handleSubmit(e)}>
                                    <Textarea name="message" defaultValue={message} height="40" />

                                    <HStack mt="4" justifyContent={"end"}>
                                        <Button type="submit" colorScheme="green">
                                            Ok
                                        </Button>
                                        <Button colorScheme="red" onClick={() => setEditMode(false)}>
                                            Cancel
                                        </Button>
                                    </HStack>
                                </form>
                            </ModalBody>
                        </ModalContent>
                    </Modal>
                </>
            )}
        </Box>
    )
}

export default LeaderMessage
